<template>
  <WalletComponent />
</template>

<script>
import WalletComponent from "@/components/WalletComponent";


export default {
name: "MyWallet",
components: { WalletComponent },



}
</script>

<style>

</style>