<template>
  <div>
    <v-card elevation="0" class="mx-auto my-5 pa-2" max-width="600">
      <h3 class="text-center ma-1">{{ localizedText[language].myWallet }}</h3>
      <h5 class="text-center ma-1">
        {{ localizedText[language].availableBalance }}
      </h5>


      <v-list outlined v-for="t in wallet.transactions" :key="t.id" three-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title :style="t.type == 'Debit' ? 'color:red' : 'color:green'">
              {{ t.amount }} {{ localizedText[language].kd }}</v-list-item-title
            >
            <v-list-item-subtitle
              class="text--primary"
              v-text="t.notes"
            ></v-list-item-subtitle>
          </v-list-item-content>

            <v-list-item-action>
                 <v-list-item-action-text>{{ t.createdAt | dateFormat("YYYY/MM/DD") }}</v-list-item-action-text>
            </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "WalletComponent",
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      customerInfo: "customerInfo",
      currency: "currency",
    }),
    ...mapGetters("Wallet", {
      wallet: "wallet",
    }),
  },
  data: () => ({
      isLoading: false,
    localizedText: {
      en: {
        myWallet: "My E-Wallet",
        availableBalance: "Available Balance",
        kd: "KD",
      },
      ar: {
        myWallet: "محفظتي",
        availableBalance: "الرصيد المنوفر",
        kd: "د.ك",
      },
    },
  }),

  methods: {
      loadWallet: async function(){
            await this.$store.dispatch("Wallet/loadWallet", this.language);
      }
  },

  async mounted() {
      await this.loadWallet();
  },
};
</script>

<style>
</style>

