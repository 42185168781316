<template>
  <div>
    <v-card class="mx-1 my-1 pa-2">
      <div
        v-if="order.status != 'Canceled'"
        dir="ltr"
        class="d-flex justify-center align-center"
      >
        <v-avatar size="45" :class="preparingClass">
          <v-icon large :dark="preparingClass == 'black'">
            mdi-package-variant-closed
          </v-icon>
        </v-avatar>
        <v-sheet
          :color="outForDeliveryClass"
          class="flex-grow-1"
          height="4px"
        ></v-sheet>
        <v-avatar size="45" :class="outForDeliveryClass">
          <v-icon large :dark="outForDeliveryClass == 'black'">
            mdi-truck-delivery
          </v-icon>
        </v-avatar>
        <v-sheet
          :color="deliveredClass"
          class="flex-grow-1"
          height="4px"
        ></v-sheet>
        <v-avatar large size="45" :class="deliveredClass">
          <v-icon large :dark="deliveredClass == 'black'">
            mdi-hand-heart
          </v-icon>
        </v-avatar>
      </div>
    </v-card>
    <v-card class="mx-1 my-1">
      <v-card-text>
        <div>{{ localizedText[language].orderSummary }}</div>

        <v-list-item dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].orderNumber }}
              {{ order.id }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].orderDate }}
              {{ order.createdAt | displayDate(isEnglish) }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].orderStatus }} {{ status }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="order.status == 'Shipped'">
            <v-list-item-action-text class="blue--text" @click="trackOrder">
              {{ localizedText[language].trackShipment }}
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
        <v-list-item dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].paymentMethod }}
              {{
                isEnglish
                  ? order.paymentMethod.englishName
                  : order.paymentMethod.arabicName
              }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="hasAdditionalPaymentMethod"
              >{{ order.paymentMethod.amount }}
              {{ currency.symbol }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="hasAdditionalPaymentMethod" dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].additionalPaymentMethod }}
              {{
                isEnglish
                  ? order.additionalPaymentMethod.englishName
                  : order.additionalPaymentMethod.arabicName
              }}
            </v-list-item-title>
            <v-list-item-subtitle
              >{{ order.additionalPaymentMethod.amount }}
              {{ currency.symbol }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].paymentStatus }} {{ paymentStatus }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
    <v-card class="mx-1 my-1">
      <v-card-text>
        <div>{{ localizedText[language].orderDetail }}</div>
        <v-list-item
          class="ma-0 pa-0"
          dense
          v-for="item in order.lineItems"
          :key="item.id"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>{{
              item | displayPrice(currency, isEnglish)
            }}</v-list-item-subtitle>
            <v-list-item-subtitle
              class="text-wrap"
              v-if="item.itemType == 'GiveawayService'"
            >
              <v-chip class="ma-1">
                {{ item.contentSize }}
              </v-chip>
              <span v-for="picker in item.optionPickers" :key="picker.id">
                <v-chip
                  class="ma-1"
                  v-if="picker.optionPickerType != 'FileAttachment'"
                >
                  <span>{{ picker.name }}</span>
                  <span> : </span>
                  <span
                    v-if="
                      picker.optionPickerType == 'SingleSelect' ||
                      picker.optionPickerType == 'ImageOnly' ||
                      picker.optionPickerType == 'SingleSelectWithImage'
                    "
                    >{{
                      picker.selectedOption.name | displayName(isEnglish)
                    }}</span
                  >

                  <span v-if="picker.optionPickerType == 'TextInput'">{{
                    picker.selectedOption.text
                  }}</span>
                  <span v-if="picker.optionPickerType == 'InfoOnly'">{{
                    picker.description
                  }}</span>
                </v-chip>
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.itemType == 'GiveawayService'">{{
              localizedText[language].deliveryDate
            }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.itemType == 'GiveawayService'">{{
              item.deliveryDay
            }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.withCard == true"
              >{{ localizedText[language].cardInfo }}
              {{ item.cardText }}</v-list-item-subtitle
            >
            <v-list-item-subtitle v-if="item.withPlate == true"
              >{{ localizedText[language].plateInfo }}
              {{ item.plateText }}</v-list-item-subtitle
            >
            <v-list-item-subtitle v-if="item.withPlate == true"
              >{{ localizedText[language].plateCost }}
              {{ item.plateCost * currency.value }}
              {{ currency.symbol }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].total }}
              {{ order.totalAfterDiscount * currency.value }}
              {{ currency.symbol }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!isKuwait" dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].deliveryFees }}
              {{ order.shippingCost * currency.value }}
              {{ currency.symbol }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="hasDeliverySpeen" dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].deliverySpeed }}
              {{
                order.deliverySpeed | displayDeliverySpeedName(isEnglish)
              }}</v-list-item-title
            >
            <v-list-item-subtitle
              >{{ order.deliverySpeed.costs }}
              {{ currency.symbol }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].grandTotal }}
              {{ order.totalAmountToBePaid * currency.value }}
              {{ currency.symbol }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
    <v-card class="mx-1 my-1">
      <v-card-text>
        <div>{{ localizedText[language].deliveryAddress }}</div>
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="black">mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              order.shippingAddress.phone
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon color="black">mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              order.shippingAddress.email
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].country }}
              {{ order.shippingAddress.country }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="!isKuwait">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].government }}
              {{ order.shippingAddress.government }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="!isKuwait">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].town }}
              {{ order.shippingAddress.town }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="isKuwait">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].area }}
              {{ order.shippingAddress.area }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="isKuwait">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].block }}
              {{ order.shippingAddress.block }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].street }}
              {{ order.shippingAddress.street }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="order.shippingAddress.addressType == 'House'">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].house }}
              {{ order.shippingAddress.house }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="order.shippingAddress.addressType != 'House'">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].building }}
              {{ order.shippingAddress.building }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="order.shippingAddress.addressType != 'House'">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].floor }}
              {{ order.shippingAddress.floor }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          dense
          v-if="order.shippingAddress.addressType == 'Apartment'"
        >
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].flat }}
              {{ order.shippingAddress.flat }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="order.shippingAddress.addressType == 'Office'">
          <v-list-item-content>
            <v-list-item-title
              >{{ localizedText[language].office }}
              {{ order.shippingAddress.office }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>{{
              localizedText[language].additionalInfo
            }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ order.shippingAddress.deliveryInstructions }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "OrderDetailComponent",
  //********************** */
  props: {
    order: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      currency: "currency",
    }),

    ...mapGetters("Catalog", {
      countries: "countries",
    }),

    preparingClass: function () {
      return this.order.status == "Preparing" ||
        this.order.status == "OutForDelivery" ||
        this.order.status == "Shipped" ||
        this.order.status == "Delivered"
        ? "black"
        : "grey lighten-3";
    },
    outForDeliveryClass: function () {
      return this.order.status == "OutForDelivery" ||
        this.order.status == "Shipped" ||
        this.order.status == "Delivered"
        ? "black"
        : "grey lighten-3";
    },
    deliveredClass: function () {
      return this.order.status == "Delivered" ? "black" : "grey lighten-3";
    },
    hasAdditionalPaymentMethod: function () {
      if (this.order.additionalPaymentMethod) {
        return true;
      }
      return false;
    },
    hasDeliverySpeen: function () {
      if (this.order.deliverySpeed) {
        if (this.order.deliverySpeed.deliverySpeedOptionId) {
          return true;
        }
      }
      return false;
    },
  },
  data: () => ({
    isEnglish: false,
    paymentStatus: "",
    status: "",
    isKuwait: true,
    localizedText: {
      en: {
        orderSummary: "Order Summary",
        orderDate: "Date :",
        orderStatus: "Status :",
        paymentMethod: "Payment Method :",
        paymentStatus: "Payment Status :",
        total: "Total :",
        deliveryFees: "Delivery fees :",
        grandTotal: "Grand total :",
        deliveryAddress: "Delivery address :",
        country: "Country :",
        orderDetail: "Order detail :",
        governorate: "Governorate :",
        town: "Town :",
        area: "Area :",
        block: "Block :",
        street: "Street :",
        house: "House :",
        building: "Building :",
        floor: "Floor :",
        flat: "Flat :",
        office: "Office :",
        additionalInfo: "Additional delivery instructions",
        trackShipment: "Track shipment",
        additionalPaymentMethod: "Additional payment method :",
        deliverySpeed: "Delivery Option: ",
        orderNumber: "Order number :",
        deliveryDate: "Date to deliver :",
        cardInfo: "Card :",
        plateInfo: "Plate :",
        plateCost: "Plate cost :",
        newOrder: "New Order",
        canceled: "Canceled",
        delivered: "Delivered",
        outForDelivery: "Out For Delivery",
        preparing: "Preparing",
        shipped: "Shipped",
        paymentReceived: "Payment Received",
        pendingPayment: "pending Payment",
        refunded: "Refunded",
      },
      ar: {
        orderSummary: "ملخص الطلب",
        orderDate: "التاريخ :",
        orderStatus: "حالة الطلب :",
        paymentMethod: "طريقة الدفع :",
        paymentStatus: "حالة الدفع :",
        total: "المجموع :",
        deliveryFees: "التوصيل :",
        grandTotal: "المجموع الكلي :",
        deliveryAddress: "عنوان التوصيل :",
        country: "الدولة :",
        orderDetail: "تفاصيل الطلب :",
        governorate: "المحافظة :",
        town: "المدينة :",
        area: "المنطقة :",
        block: "القطعة :",
        house: "البيت :",
        building: "العمارة :",
        floor: "الدور :",
        flat: "االشقة :",
        office: "المكتب :",
        additionalInfo: "تعليمات اضافية للتوصيل",
        trackShipment: "تتبع الشحنة",
        additionalPaymentMethod: "طريقة دفع اضافية :",
        deliverySpeed: "خيار التوصيل :",
        orderNumber: "رقم الطلب :",
        deliveryDate: "التاريخ المطلوب :",
        cardInfo: "الكرت :",
        plateInfo: "البليت :",
        plateCost: "سعر البليت :",
        newOrder: "طلب جديد",
        canceled: "ملغي",
        delivered: "تم التوصيل",
        outForDelivery: "في الطريق إليك",
        preparing: "يتم تجهيز الطلب",
        shipped: "تم الشحن",
        paymentReceived: "تم الدفع",
        pendingPayment: "في انتظار الدفع",
        refunded: "تم ارجاع المبلغ",
      },
    },
  }),

  methods: {

    displayStatus: function (status) {
      if (status == "new") {
        return this.localizedText[this.language].newOrder;
      } else if (status == "Canceled") {
        return this.localizedText[this.language].canceled;
      } else if (status == "Delivered") {
        return this.localizedText[this.language].delivered;
      } else if (status == "OutForDelivery") {
        return this.localizedText[this.language].outForDelivery;
      } else if (status == "Preparing") {
        return this.localizedText[this.language].preparing;
      } else if (status == "Shipped") {
        return this.localizedText[this.language].shipped;
      }

      return status;
    },

    displayPaymentStatus: function (status) {
      if (status == "Completed") {
        return this.localizedText[this.language].paymentReceived;
      } else if (status == "NotCompleted ") {
        return this.localizedText[this.language].pendingPayment;
      } else if (status == "Refunded") {
        return this.localizedText[this.language].refunded;
      }

      return status;
    },

    trackOrder: function () {
      window.open(this.order.trackingUrl, '_blank')
    },
  },

  filters: {
    displayName(name, isEnglish) {
      if (isEnglish) {
        return name.english;
      } else {
        return name.arabic;
      }
    },
    displayDeliverySpeedName(delivery, isEnglish) {
      if (isEnglish) {
        return delivery.englishTitle;
      } else {
        return delivery.arabicTitle;
      }
    },
    displayPrice(item, currency, isEnglish) {
      if (item.promotion != null) {
        if (item.promotion.promotionType === "Discount") {
          let price =
            item.promotion.discountPrice * item.quantity * currency.value;

          if (isEnglish) {
            return `${item.quantity} x ${
              item.promotion.discountPrice * currency.value
            } ${currency.symbol} = ${price} ${currency.symbol}`;
          } else {
            return `${item.quantity} * ${
              item.promotion.discountPrice * currency.value
            } ${currency.symbol} = ${price} ${currency.symbol}`;
          }
        }
      }

      let price = item.unitPrice * item.quantity * currency.value;

      if (isEnglish) {
        return `${item.quantity} x ${item.unitPrice * currency.value} ${
          currency.symbol
        } = ${price} ${currency.symbol}`;
      } else {
        return `${item.quantity} * ${item.unitPrice * currency.value} ${
          currency.symbol
        } = ${price} ${currency.symbol}`;
      }
    },
    displayDate(dateString, isEnglish) {
      //var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      let date = new Date(dateString);
      var locale = "en-US";
      if (!isEnglish) {
        locale = "ar-KW";
      }
      return date.toLocaleString(locale);
    },
  },

  mounted() {
    this.isEnglish = this.language == "en";
    this.paymentStatus = this.displayPaymentStatus(this.order.paymentStatus);
    this.status = this.displayStatus(this.order.status);
    this.isKuwait = this.countryCode == "KW";
  },
};
</script>

<style>
</style>