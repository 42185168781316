<template>
  <div>
    <v-data-iterator
      :items="orders"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-card
          v-for="order in props.items"
          :key="order.id"
          class="ma-1 d-flex justify-space-between pa-2 align-center"
        >
          <div >{{ order.id }}</div>
          <v-chip outlined :color="statusColor(order.status)">{{ displayStatus(order.status) }}</v-chip>
          <div class="caption">
            {{ order.createdAt | dateFormat("YYYY/MM/DD") }}
          </div>
          <div class="caption">{{ order.totalAmountToBePaid }} KD</div>
          <v-btn @click="openDetail(order)" icon>
            <v-icon> mdi-information-outline </v-icon>
          </v-btn>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-card flat class="pa-2" v-if="numberOfPages > itemsPerPage">
          <span class="mr-4 grey--text">
            {{ localizedText[language].page }} {{ page }}
            {{ localizedText[language].of }} {{ numberOfPages }}
          </span>
          <v-btn icon class="mr-1" @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "OrdersComponent",

  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      currency: "currency",
    }),

    ...mapGetters("Order", {
      orders: "orders",
    }),

    numberOfPages() {
      return Math.ceil(this.orders.length / this.itemsPerPage);
    },
  },
  data: () => ({
    isLoading: false,
    page: 1,
    itemsPerPage: 5,
    localizedText: {
      en: {
        page: "Page",
        of: "of",
        newOrder: "New Order",
        canceled: "Canceled",
        delivered: "Delivered",
        outForDelivery: "Out For Delivery",
        preparing: "Preparing",
        shipped: "Shipped",
      },
      ar: {
        page: "صفحة",
        of: "من",
        newOrder: "طلب جديد",
        canceled: "ملغي",
        delivered: "تم التوصيل",
        outForDelivery: "في الطريق إليك",
        preparing: "يتم تجهيز الطلب",
        shipped: "تم الشحن",
      },
    },
  }),
  methods: {
    displayStatus: function (status) {
        if(status == 'new'){
            return this.localizedText[this.language].newOrder;
        } else  if(status == 'Canceled'){
            return this.localizedText[this.language].canceled;
        } else  if(status == 'Delivered'){
            return this.localizedText[this.language].delivered;
        } else  if(status == 'OutForDelivery'){
            return this.localizedText[this.language].outForDelivery;
        } else  if(status == 'Preparing'){
            return this.localizedText[this.language].preparing;
        } else  if(status == 'Shipped'){
            return this.localizedText[this.language].shipped;
        }

        return status;
    },

    statusColor: function (status) {
        if(status == 'new'){
            return '';
        } else  if(status == 'Canceled'){
            return 'red';
        } else  if(status == 'Delivered'){
            return 'green';
        } else  if(status == 'OutForDelivery'){
            return 'deep-purple';
        } else  if(status == 'Preparing'){
            return 'indigo';
        } else  if(status == 'Shipped'){
            return 'secondary';
        }

        return '';
    },
    loadOrders: async function () {
      this.isLoading = true;
      await this.$store.dispatch("Order/loadOrders", this.language);
      this.isLoading = false;
    },
    formerPage: function () {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    nextPage: function () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },

    openDetail: function(order){
        this.$router.push({ path: `/orderdetail/${order.id}` });
    }
  },
  async mounted() {
    await this.loadOrders();
  },
};
</script>

<style>
</style>