<template>
  <ShareAppSignUpComponent v-if="sharerCustomerId > 0" :sharerCustomerId="sharerCustomerId"/>
</template>

<script>
import ShareAppSignUpComponent from "@/components/ShareAppSignUpComponent.vue";


export default {
name: "Item",
components: { ShareAppSignUpComponent },
//********************** */
 data: () => ({
     sharerCustomerId: 0
 }),
methods: {
    loadInfo: function(){
        const sharerCustomerId =  this.$route.query.id;
        this.sharerCustomerId = Number.parseInt(sharerCustomerId, 0); 
    }
},
 
 mounted() {
     this.loadInfo();
 },


}
</script>

<style>

</style>