<template>
  <div>
       
    <v-card flat class="ma-auto" max-width="600">
        <v-breadcrumbs large :items="breadcrumbItems">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
            <v-avatar v-if="item.imageUrl" size="25">
              <v-img :src="item.imageUrl"></v-img>
            </v-avatar>
            <span :style="`color: ${item.color}`">
              {{ item.text }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <OrderDetailComponent v-if="order" :order="order" />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrderDetailComponent from "@/components/OrderDetailComponent";
export default {
  name: "OrderDetail",
  components: { OrderDetailComponent },
  //********************** */
  props: {
    id: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      currency: "currency",
    }),

    ...mapGetters("Order", {
      orders: "orders",
    }),
  },

  data: () => ({
       breadcrumbItems: [],
    order: null,
    localizedText: {
      en: {
         home: "Home",
         myOrders: "My orders"
      },
      ar: {
        home: "الرئيسية",
        myOrders: "طلباتي"
      },
    },
  }),

  async mounted() {

       this.breadcrumbItems.push({
        text: this.localizedText[this.language].home,
        disabled: false,
        to: "/",
        color: "black",
        imageUrl: require("@/assets/square.png"),
      });

      this.breadcrumbItems.push({
        text: this.localizedText[this.language].myOrders,
        disabled: false,
        to: `/MyOrders`,
        color: "black",
        imageUrl: null,
      });

      this.breadcrumbItems.push({
        text: this.id,
        disabled: true,
        to: `/orderdetail`,
        color: "black",
        imageUrl: "",
      });

    if(this.orders == null || this.orders == undefined || this.orders.length == 0){
        await this.$store.dispatch("Order/loadOrders", this.language);
    }
    let id = this.id;
    this.order = this.orders.find((o) => o.id == id);
  
  },
};
</script>

<style>
</style>