<template>
  <div>
    <v-card flat class="mx-auto pa-2" max-width="600">
         <v-breadcrumbs large :items="breadcrumbItems">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
            <v-avatar v-if="item.imageUrl" size="25">
              <v-img :src="item.imageUrl"></v-img>
            </v-avatar>
            <span :style="`color: ${item.color}`">
              {{ item.text }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card-title>{{localizedText[language].myOrders}}</v-card-title>
      <OrdersComponent />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrdersComponent from "@/components/OrdersComponent";
export default {
  name: "MyOrders",
  components: { OrdersComponent },
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      currency: "currency",
    }),

  },
   data: () => ({
       breadcrumbItems: [],
   
    localizedText: {
      en: {
         home: "Home",
         myOrders: "My orders"
      },
      ar: {
        home: "الرئيسية",
        myOrders: "طلباتي"
      },
    },
  }),

  mounted(){
       this.breadcrumbItems.push({
        text: this.localizedText[this.language].home,
        disabled: false,
        to: "/",
        color: "black",
        imageUrl: require("@/assets/square.png"),
      });

      this.breadcrumbItems.push({
        text: this.localizedText[this.language].myOrders,
        disabled: true,
        to: `/MyOrders`,
        color: "black",
        imageUrl: null,
      });
  }
};
</script>

<style>
</style>