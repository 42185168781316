<template>
<div>


 <v-card v-if="!isRegistered" flat tile max-width="500" class="mx-auto my-1">
    <v-img
      class="mx-auto my-1"
      max-width="150"
      height="150"
      src="@/assets/saray.png"
    ></v-img>
    <v-card-text>
      <v-card>
        <v-card-text>
          <v-text-field
            v-model="signUpModel.firstName"
            :label="localizedText[language].firstName"
          ></v-text-field>

          <v-text-field
            v-model="signUpModel.lastName"
            :label="localizedText[language].lastName"
          ></v-text-field>

          <div dir="ltr">
            <VuePhoneNumberInput
              :only-countries="countriesList"
              v-model="signUpModel.phone"
              default-country-code="KW"
              :translations="translations"
              @update="onUpdate"
            />
          </div>

          <!--           <v-text-field
            v-model="signUpModel.phone"
            :label="localizedText[language].phone"
            dir="ltr"
            prepend-icon="mdi-phone"
            :prefix="language == 'en' ? country.dialCode.substring(1) : ''"
            :suffix="language == 'ar' ? country.dialCode.substring(1) : ''"
          >
          </v-text-field> -->
          <div class="text-center">
            <v-btn
            :loading="isSendingOtp"
              :disabled="!isPhoneValid"
              class="ma-2"
              color="primary"
              text
              @click="getOtp"
            >
              {{ localizedText[language].getOtp }}</v-btn
            >
          </div>

          <v-text-field
            v-model="signUpModel.otp"
            prepend-icon="mdi-chat"
            :label="localizedText[language].otp"
          ></v-text-field>

          <v-alert
            v-if="isOtpSent"
            border="left"
            colored-border
            color="black"
            elevation="2"
          >
            {{ localizedText[language].otpSent }}
          </v-alert>
          <v-text-field
            v-model="signUpModel.username"
            prepend-icon="mdi-account-circle"
            :label="localizedText[language].email"
          ></v-text-field>

          <v-text-field
            @click:append="signUpModel.showPassword = !signUpModel.showPassword"
            :type="signUpModel.showPassword ? 'text' : 'password'"
            :append-icon="signUpModel.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="signUpModel.password"
            prepend-icon="mdi-lock"
            :label="localizedText[language].password"
          ></v-text-field>
        </v-card-text>

        <v-card-actions justify-center>
          <v-btn :loading="isSigningUp" block dark @click="signUp()">
            {{ localizedText[language].signUp }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</div>
 
</template>

<script>
import { mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  name: "ShareAppSignUpComponent",
  components: { VuePhoneNumberInput },
  props: {
    sharerCustomerId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      country: "country",
    }),
    ...mapGetters("Catalog", {
      countries: "countries",
    }),
    isPhoneValid: function () {
      if (this.signUpModel.phone) {
        return this.signUpModel.phone.length >= 8;
      }
      return false;
    },
  },
  data: () => ({
    isOtpSent: false,
    isSendingOtp: false,
    translations: {
      countrySelectorLabel: "الدولة",
      countrySelectorError: "يرجى اختيار الدولة",
      phoneNumberLabel: "رقم الهاتف",
      example: "50399591",
    },
    countriesList: ["KW", "SA", "BH", "OM", "QA", "AE"],
    isSigningUp: false,
    signUpModel: {
      username: "",
      password: "",
      showPassword: false,
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      otp: "",
      dialCode: "+965",
    },
    localizedText: {
      en: {
        username: "Username",
        password: "Password",
        signIn: "Sign In",
        signUp: "Sign Up",
        firstName: "First name",
        lastName: "Last name",
        middleName: "Middle name",
        email: "Email",
        phone: "Phone",
        forgotPassword: "Forgot password?",
        otp: "One time password (OTP)",
        getOtp: "Click here to get OTP by SMS",
        otpSent:
          "An OTP was sent to your mobile number. If you have not received it after 5 minutes please try again.",
      },
      ar: {
        username: "اسم المستخدم",
        password: "كلمة المرور",
        signIn: "تسجيل الدخول",
        signUp: "انشاء حساب",
        firstName: "الاسم الأول",
        lastName: "اللقب(الاسم الأخير)",
        middleName: "الاسم االثاني",
        email: "الايميل",
        phone: "التلفون",
        forgotPassword: "نسيت كلمة المرور؟",
        otp: "رمز المرور لمرة واحدة OTP",
        getOtp: "اضغط للحصول على رمز OTP بواسطة رسالة SMS",
        otpSent:
          "تم ارسال رمز OTP إلى رقم الهاتف الخاص بك. في حال لو تصل الرسالة خلا 5 دقائق يرجى المحاولة مرة أخرى",
      },
    },
  }),
  methods: {
    signUp: async function () {
      try {
          let phone = `${this.signUpModel.dialCode}${this.signUpModel.phone}`;

        let model = {
          firstName: this.signUpModel.firstName,
          lastName: this.signUpModel.lastName,
          phone: phone,
          email: this.signUpModel.username,
          countryCode: this.countryCode,
          password: this.signUpModel.password,
          sharerCustomerId: this.sharerCustomerId,
          otp: this.signUpModel.otp
        };

        this.isSigningUp = true;
        await this.$store.dispatch("Account/signUpWithShareCode", model);
        this.isSigningUp = false;
        this.$router.push({ path: `/` });
        await this.refreshData();
      } catch (error) {
        this.isSigningUp = false;
        alert(error);
      }
    },

    getOtp: async function () {
      try {
        this.isSendingOtp = true;
        this.isOtpSent = false;
        let phone = `${this.signUpModel.dialCode}${this.signUpModel.phone}`;
        await this.$store.dispatch("Account/oneTimePassword", phone);
        this.isSendingOtp = false;
        this.isOtpSent = true;
      } catch (error) {
        alert(error);
        this.isSendingOtp = false;
        this.isOtpSent = true;
      }
    },
    onUpdate: function (payload) {
      let selectedCountry = this.countries.find(
        (x) => x.code == payload.countryCode
      );
      this.signUpModel.dialCode = selectedCountry.dialCode;
    },
    refreshData: async function () {
      this.$store.dispatch("loadToken");
      await this.$store.dispatch("Cart/loadCart", this.language);
      await this.$store.dispatch("Catalog/loadCatalog", this.language);
      await this.$store.dispatch("Account/loadCustomerInfo");
    },
    loadCountries: async function () {
      await this.$store.dispatch("Catalog/loadCountries");
    },
  },
  async mounted() {
    await this.loadCountries();
    if(this.isRegistered){
         this.$router.push({ path: `/` });
    }

  },
};
</script>

<style>
</style>