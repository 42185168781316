<template>
  <div class="pa-4">
    <v-card

      :loading="isLoading"
      class="ma-auto"
      max-width="500"
    >
      <div v-if="!isLoading">
        <img v-if="orderResult.isSuccess"
          src="https://sarayperfumes.blob.core.windows.net/images/success.png"
          class="center"
        />
        <img v-else
          src="https://sarayperfumes.blob.core.windows.net/images/failed.png"
          class="center"
        />


        <v-list three-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{orderResult.title}}</v-list-item-title>
              <v-list-item-subtitle
                >{{orderResult.body}}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

            <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{orderResult.totalAmountTitle}}</v-list-item-title>
              <v-list-item-subtitle
                >{{orderResult.totalAmount}}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

   <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{orderResult.postDateTitle}}</v-list-item-title>
              <v-list-item-subtitle
                >{{orderResult.postDate}}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

         

        </v-list>

  <v-list-item >
            <v-list-item-content>
              <v-list-item-title class="text-center"><v-btn @click="goToHome" text>{{localizedText[language].goToHomePage}}</v-btn> </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        
   
      </div>
    </v-card>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "OrderResult",
  //********************** */
  props: {
    orderNumber: {
      type: String,
    },
    refrenceId: {
      type: String,
    },
  },

  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      currency: "currency",
    }),

    ...mapGetters("Order", {
      orderResult: "orderResult",
    }),
  },

  data: () => ({
    isLoading: false,
    localizedText: {
      en: {
        home: "Home",
        myOrders: "My orders",
        goToHomePage: "Go to home page"
      },
      ar: {
        home: "الرئيسية",
        myOrders: "طلباتي",
        goToHomePage: "الذهاب إلى الصفحة الرئسية"
      },
    },
  }),

  methods: {
      goToHome: function(){
          this.$router.push({ path: `/` });
      }
  },

  async mounted() {
    this.isLoading = true;
    let language = this.language;
    const orderNumber = this.$route.query.ordernumber;
    const refrenceId = this.$route.query.refrence;

    let model = {
      orderNumber,
      refrenceId,
      language
    };

    try {
      await this.$store.dispatch("Order/loadOrderResult", model);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      alert(error);
    }

    try {
      window.snaptr('track', 'PAGE_VIEW');
      if(this.orderResult.isSuccess){
        window.snaptr('track', 'PURCHASE');
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
 .center {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 30%;
        }
</style>